

import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({
    components: {}
})
export default class InfoBlock extends Vue {
    @Prop({required: false, type: [Array, String]})
    readonly messages!: string[] | string;

    @Prop({required: false, type: Boolean, default: true})
    readonly isAddMargins!: boolean;

    get
    calculatedMessages() {
        if (!this.messages)
            return [];
        if (Array.isArray(this.messages))
            return this.messages;
        return [this.messages];
    }
}
