

import {Component, Emit, Model, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";

@Component({
    components: {}
})
export default class CheckboxField extends Vue {
    @Model('change', { type: [Boolean, Number, String], required: false, default: false })
    readonly selectedValue!: boolean

    @Prop({required: false, type: String, default: ''})
    readonly label!: string;

    private innerSelectedValue: boolean = false;
    private checkboxId: string = '';

    created() {
        this.checkboxId = 'checkbox_' + Math.random();
        this.innerSelectedValue = this.selectedValue;
    }

    @Watch('selectedValue')
    selectedValueWatcher(selectedValue: boolean) {
        this.innerSelectedValue = selectedValue;
    }

    @Watch('innerSelectedValue')
    @Emit('change')
    innerSelectedValueWatcher(value: boolean) {}
}
