export default class ReCaptchaService {

    public static getToken(): Promise<string> {
        return new Promise<string>((resolve) => {
            const grecaptcha = (window as any).grecaptcha as {
                ready: (callback: () => void) => void,
                execute: (token: string, options: {action: 'homepage'}) => Promise<string>
            };
            grecaptcha.ready(() => {
                grecaptcha.execute('6LeGSaohAAAAAPZVD6Qz4sGE5Dge8rZVCd1etSy8', {action: 'homepage'}).then(function(captchaToken) {
                    console.info('Got ReCaptcha token:', captchaToken);
                    resolve(captchaToken);
                });
            });
        });
    }
}
