import { createDecorator } from 'vue-class-component'
import {VueDecorator} from "vue-class-component/lib/util";

export type DebounceOptions =
    | number
    | {
    time: number
}

export function Debounce(options: DebounceOptions): VueDecorator {
    return createDecorator((opts, handler) => {
        if (!opts.methods) throw new Error('This decorator must be used on a vue component method.')

        const time = typeof options === 'number' ? options : options.time

        const originalFn = opts.methods[handler]
        let timeoutId = 0

        const clear = () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
                timeoutId = 0
            }
        }

        opts.methods[handler] = function(...args: any[]) {
            clear()
            // @ts-ignore
            timeoutId = setTimeout(() => {
                timeoutId = 0
                originalFn.apply(this, args)
            }, time)
        }
    })
}
