import * as _ from 'lodash';

export default class I18nService
{
    private key: string;

    /**
     * Initialize a new translation instance.
     *
     * @param  {string}  key
     * @return {void}
     */
    constructor(key = 'translations')
    {
        this.key = key;
    }

    /**
     * Get and replace the string of the given key.
     *
     * @param  {string}  key
     * @param  {object}  replace
     * @return {string}
     */
    public trans(key: string, replace = {})
    {
        return this._replace(this._extract(key), replace);
    }

    /**
     * Get and pluralize the strings of the given key.
     *
     * @param  {string}  key
     * @param  {number}  count
     * @param  {object}  replace
     * @return {string}
     */
    trans_choice(key: string, count = 1, replace = {})
    {
        // eslint-disable-next-line prefer-const
        let translations = this._extract(key, '|').split('|'), translation;

        translations.some((t: any) => translation = this._match(t, count));

        translation = translation || (count > 1 ? translations[1] : translations[0]);

        translation = translation.replace(/\[.*?\]|\{.*?\}/, '');

        return this._replace(translation, replace);
    }

    /**
     * Match the translation limit with the count.
     *
     * @param  {string}  translation
     * @param  {number}  count
     * @return {string|null}
     */
    _match(translation: string, count: number)
    {
        const match = translation.match(/^[{[]([^[\]{}]*)[}\]](.*)/);

        if (! match) return;

        if (match[1].includes(',')) {
            const [from, to] = match[1].split(',', 2);

            if (to === '*' && count >= parseInt(from)) {
                return match[2];
            } else if (from === '*' && count <= parseInt(to)) {
                return match[2];
            } else if (count >= parseInt(from) && count <= parseInt(to)) {
                return match[2];
            }
        }

        return parseInt(match[1]) == count ? match[2] : null;
    }

    /**
     * Replace the placeholders.
     *
     * @param  {string}  translation
     * @param  {object}  replace
     * @return {string}
     */
    _replace(translation: string, replace: any)
    {
        if (typeof translation === 'object') {
            return translation;
        }

        for (const placeholder in replace) {
            const stringToReplace = replace[placeholder] ? `${replace[placeholder]}` : '';
            translation = translation.toString()
                .replace(`:${placeholder}`, stringToReplace)
                .replace(`:${placeholder.toUpperCase()}`, stringToReplace.toUpperCase())
                .replace(
                    `:${placeholder.charAt(0).toUpperCase()}${placeholder.slice(1)}`,
                    stringToReplace.charAt(0).toUpperCase() + stringToReplace.slice(1)
                );
        }

        return translation.toString().trim()
    }

    /**
     * Extract values from objects by dot notation.
     *
     * @param  {string}  key
     * @param  {mixed}  value
     * @return {mixed}
     */
    _extract(key: string, value: any = null): string
    {
        const path = `${key}`.split('::'),
            keys = `${path.pop()!}`.split('.');

        if (path.length > 0) {
            path[0] += '::';
        }

        // @ts-ignore
        return path.concat(keys)
            .reduce(
                (t, i) => _.get(t, i) || (value || key),
                _.get(window, this.key, [])
            );
    }
}
