import './extensions';
import '@/common/VueFilters'
import '@/common/icons'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueMask, { VueMaskDirective } from 'v-mask'
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCompositionAPI from '@vue/composition-api'
import VueObserveVisibility from 'vue-observe-visibility'

axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if (error.response.data) {
        return Promise.resolve(error.response);
    }
    return Promise.reject(error.response);
});

Vue.config.productionTip = false

Vue.use(VueObserveVisibility);
Vue.use(VueCompositionAPI)
Vue.use(VueAxios, axios);
Vue.use(VueMask);
Vue.directive('mask', VueMaskDirective);

