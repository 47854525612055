import Vue from 'vue'
import * as _ from 'lodash'
// import {IValidatedField} from "@/common/element/form/IValidatedField";
import I18nService from "./I18nService";

interface IValidatedField {
    validate(): boolean;
}

export default class Utils {

    public static LangService: I18nService = new I18nService();

    public static phrase(phraseId: string, params: object = {}) : string {
        return Utils.LangService.trans(phraseId, params);
    }

    public static mergeArraysById(destinationArray: any[], sourceArray: any[], idProp: string = 'id') {
        _.forEach(sourceArray, sourceArrayItem => {
            this.mergeArrayById(destinationArray, sourceArrayItem, idProp);
        });
    }

    public static mergeArrayById(arr: any[], obj: object, idProp: string = 'id') {
        const index = _.findIndex(arr, function (elem) {
            // double check, since undefined === undefined
            return typeof elem[idProp] !== "undefined" && elem[idProp] === _.get(obj, idProp);
        });

        if (index > -1) {
            Vue.set(arr, index, obj);
            // arr[index] = obj;
        } else {
            arr.push(obj);
        }

        return arr;
    }

    public static mergeObjects(from: object, to: object) {
        _.forEach(_.keys(to), key => {
            Vue.set(to, key, _.get(from, key));
        });
    }

    public static runValidateFunction(formObject: object, $refs: { [key: string]: Vue | Element | (Vue | Element)[] | IValidatedField | IValidatedField[] | undefined }) {
        let isValid = true;
        _.map(formObject, (value, key) => {
            let field = _.get($refs, key);
            if (_.isArray(field)) {
                field = field as IValidatedField[];
                _.forEach(field, innerField => {
                    const result = innerField.validate();
                    if (!result) {
                        isValid = false;
                    }
                });
            } else if (field && this.isValidationField(field)) {
                field = field as IValidatedField;
                const result = field.validate();
                if (!result) {
                    isValid = false;
                }
            }
        });
        return isValid;
    }

    private static isValidationField(object: any): object is IValidatedField {
        return 'validate' in object;
    }

    public static deleteAllFromArrayById (arr: any[], value: string | number, idProp: string = 'id') {
        const itemsToDelete = _.filter(arr, arrValue => arrValue[idProp] === value);
        _.forEach(itemsToDelete, item => {
            this.deleteFromArrayById(arr, item.id);
        });
    }

    public static deleteFromArrayById (arr: any[], value: string | number | boolean | null | undefined, idProp: string = 'id') {
        const index = _.findIndex(arr, function (elem) {
            // double check, since undefined === undefined
            return typeof elem[idProp] !== "undefined" && elem[idProp] === value;
        });

        Vue.delete(arr, index);
        return arr;
    }

    public static toMoneyFormat(value: number) {
        return new Intl.NumberFormat('ua-UA', {
            // notation: 'standard',
            // style: 'currency',
            // currency: 'UAH',
            // signDisplay: 'exceptZero',
            // currencySign: 'accounting',
        }).format(value) + ' ₽';
    }

    public static fromReactiveObject(object: object | any[]) {
        return JSON.parse(JSON.stringify(object));
    }

    public static generateId() {
        return Math.round(Math.random() * 10000000000);
    }

    public static getFullTimeString(time: number) {
        const date = new Date(time * 1000);
        return `${date.getUTCHours()}:${date.getUTCMinutes()} ${date.getUTCDay()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
    }
}
