

import {Component, Emit, Model, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";

@Component({
    components: {}
})
export default class QuantityInputField extends Vue {
    @Model('change', { required: false, type: Number, default: 1 })
    private fieldValue!: number

    @Prop({required: false, type: Number})
    readonly defaultValue!: number;

    @Prop({required: false, type: Boolean, default: false})
    readonly disabled!: boolean;

    private innerFieldValue: number = 1;

    created() {
        if (this.defaultValue) {
            this.innerFieldValue = this.defaultValue;
        } else {
            this.innerFieldValue = this.fieldValue;
        }
    }

    clickUp() {
        if (this.disabled)
            return;
        this.changeField(this.innerFieldValue + 1);
    }

    clickDown() {
        if (this.disabled)
            return;
        if (this.innerFieldValue > 0) {
            this.changeField(this.innerFieldValue - 1);
        }
    }

    @Emit('change')
    changeField(value: number) {
        this.innerFieldValue = value;
    }
}
