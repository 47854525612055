import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faHeart as faHeartSolid,
    faInfo as faInfoSolid,
    faMinus as faMinusSolid,
    faPlus as faPlusSolid,
    faXmark as faXmarkSolid,
    faHome as faHomeSolid,
    faList as faListSolid,
    faEnvelope as faEnvelopeSolid,
    faAngleUp as faAngleUpSolid,
    faAngleDown as faAngleDownSolid,
    faMagnifyingGlass as faMagnifyingGlassSolid,
    faCircleNotch as faCircleNotchSolid,
    faTableCellsLarge as faTableCellsLargeSolid,
    faCircleUser as faCircleUserSolid,
    faStar as faStarSolid,
    faTrash as faTrashSolid,
    faFloppyDisk as faFloppyDiskSolid,
    faAngleRight as faAngleRightSolid,
    faPen as faPenSolid,
    faPhone as faPhoneSolid,
} from '@fortawesome/free-solid-svg-icons'
import {
    faHeart,
    faStar,
} from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(
    faStar,
    faHeart,
    faXmarkSolid,
    faHeartSolid,
    faInfoSolid,
    faMinusSolid,
    faPlusSolid,
    faHomeSolid,
    faListSolid,
    faEnvelopeSolid,
    faAngleUpSolid,
    faAngleDownSolid,
    faMagnifyingGlassSolid,
    faCircleNotchSolid,
    faTableCellsLargeSolid,
    faCircleUserSolid,
    faStarSolid,
    faTrashSolid,
    faFloppyDiskSolid,
    faAngleRightSolid,
    faPenSolid,
    faPhoneSolid
)
Vue.component('fa-icon', FontAwesomeIcon)
