import {ApiService} from "@/common/service/ApiService";
import {
    AppInitResponse,
    FilterDataResponse,
    LoginStep1Response,
    LoginStep2Response,
    QuickSearchResponse
} from "@/main/types/apiResponse";
import {
    BasketEntity,
    BrandEntity,
    DeliveryServiceEntity,
    OrderEntity,
    ProductCommentEntity,
    ProductEntity
} from "@/main/types/entity";
import ReCaptchaService from "@/main/service/ReCaptchaService";
import {
    AddCommentRequestModel,
    FindProductsRequest, GetCategoriesShowcaseRequest,
    SendFeedbackRequestModel
} from "@/main/types/apiRequest";
import {ApiResponseModel, ApiResponseModel2, ListResponseModel} from "../../common/type/apiRequest";

class SiteApiService extends ApiService {

    getInitData(): Promise<ApiResponseModel2<AppInitResponse>> {
        return this.getRequest2<AppInitResponse>(`/api/init`);
    }

    async productsGetList(request: FindProductsRequest) {
        return await this.postRequest2<ListResponseModel<ProductEntity>>('/api/product/list', request);
    }

    async productGet(id: number) {
        return await this.getRequest2<ProductEntity>('/api/product/' + id);
    }

    async productsGetShowcaseList() {
        return await this.getRequest2<ListResponseModel<ProductEntity>>('/api/product/showcase');
    }

    async productsGetCategoriesShowcaseList(categoryId: number) {
        const request = new GetCategoriesShowcaseRequest();
        request.categoryId = categoryId;
        return await this.postRequest2<ListResponseModel<ProductEntity>>('/api/product/showcase-category', request);
    }

    async productsWishListSet(isAdd: boolean, productId: number) {
        return await this.postRequest2('/api/wishlist/set', { productId, isAdd });
    }

    async productsWishListGet(page: number = 1) {
        return await this.getRequest2<ListResponseModel<ProductEntity>>(`/api/wishlist/list/${page}`);
    }

    async basketGet() {
        return await this.getRequest2<BasketEntity>('/api/basket/get');
    }

    async basketSetProduct(productId: number, quantity: number) {
        return await this.postRequest2<BasketEntity>('/api/basket/product/set', {
            productId,
            quantity
        });
    }

    async orderAdd(query: OrderEntity) {
        return await this.postRequest2<OrderEntity>('/api/basket/checkout', query);
    }

    filterGetData(
        categoryIdOrShortName: string | number | null,
        brandIdOrShortName: string | number | null,
        searchString: string | null
    ) {
        return this.postRequest2<FilterDataResponse>('/api/filter/get', {
            categoryId: categoryIdOrShortName,
            brandId: brandIdOrShortName,
            searchString
        });
    }

    async deliveryServicesGetList(page: number = 1) {
        return await this.getRequest2<ListResponseModel<DeliveryServiceEntity>>('/api/delivery_service/list/' + page);
    }

    async orderList(page: number = 1) {
        return await this.getRequest2<ListResponseModel<OrderEntity>>('/api/order/users-list/' + page);
    }

    async orderGetBuyButton(orderNumber: number) {
        return await this.postRequest2<string>('/api/order/get_buy_button', { orderNumber });
    }

    async userLoginStep1(phoneNumber: string) {
        return new Promise<ApiResponseModel2<LoginStep1Response>>(async (resolve, reject) => {
            const token = await ReCaptchaService.getToken();
            if (token) {
                const result = await this.postRequest2<LoginStep1Response>(
                    '/api/user/login/step1',
                    { phoneNumber, captchaToken: token }
                );
                resolve(result);
                return;
            }
            reject();
        });
    }

    async userLoginStep2(confirmationCode: string, requestToken: string) {
        return await this.postRequest2<LoginStep2Response>(
            '/api/user/login/step2',
            { confirmationCode, requestToken }
        );
    }

    async userRegistrationStep1(email: string) {
        return new Promise<ApiResponseModel>(async (resolve, reject) => {
            const token = await ReCaptchaService.getToken();
            if (token) {
                const result = await this.postRequest2<LoginStep1Response>(
                    '/api/user/registration/step1',
                    { email, captchaToken: token }
                );
                resolve(result);
                return;
            }
            reject();
        });
    }

    async userRegistrationStep2(verificationToken: string) {
        return new Promise<ApiResponseModel>(async (resolve, reject) => {
            const token = await ReCaptchaService.getToken();
            if (token) {
                const result = await this.postRequest2<LoginStep1Response>(
                    '/api/user/registration/step2',
                    { verificationToken, captchaToken: token }
                );
                resolve(result);
                return;
            }
            reject();
        });
    }

    async userLogout() {
        return await this.postRequest2<ApiResponseModel>('/api/user/logout');
    }

    async commentsList(productId: number, page: number = 1) {
        return await this.getRequest2<ListResponseModel<ProductCommentEntity>>(
            `/api/product/comment/${productId}/${page}`
        );
    }

    async commentAdd(data: AddCommentRequestModel) {
        return await this.postRequest2<ProductCommentEntity>('/api/product/comment/add', data);
    }

    // async pageGet(pageIdOrUrl: string) {
    //     return this.prepareResponse<ProductCommentEntity>(
    //         await this.postRequest('/api/page/get', { pageIdOrUrl })
    //     );
    //     await this.postRequest('/api/page/get', { pageIdOrUrl });
    // }

    async brandsGetList(page: number) {
        return await this.getRequest2<ListResponseModel<BrandEntity>>(`/api/brand/list/${page}`);
    }

    async feedbackSendList(data: SendFeedbackRequestModel) {
        return new Promise<ApiResponseModel2<any>>(async (resolve, reject) => {
            const captchaToken = await ReCaptchaService.getToken();
            if (captchaToken) {
                const result = await this.postRequest2('/api/feedback/send', { ...data, captchaToken });
                resolve(result);
                return;
            }
            reject();
        });
    }

    async filterQuickSearch(searchString: string) {
        return await this.postRequest2<QuickSearchResponse>('/api/filter/quick-search', { searchString });
    }
}

export default new SiteApiService();
