

import {Component, Emit, Prop} from "vue-property-decorator";
import Vue from "vue";
import {CustomModalButton} from "@/common/type/system";

@Component({
    components: {}
})
export default class CustomModal extends Vue {
    @Prop({required: false, type: Boolean})
    readonly isShow!: boolean;

    @Prop({required: false, type: Array})
    readonly buttons!: CustomModalButton[];

    @Prop({required: false, type: String})
    readonly title!: string;

    @Prop({required: false, type: Boolean, default: false})
    readonly isLoading!: boolean;

    @Prop({required: false, type: Boolean, default: true})
    readonly isCloseByBackgroundClick!: boolean;

    @Prop({required: false, type: Boolean, default: true})
    readonly isScrollable!: boolean;

    @Prop({required: false, type: Boolean, default: false})
    readonly isLarge!: boolean;

    @Emit('close')
    closeModal() {}

    get
    isLoadingState() {
        return this.isLoading || false;
    }

    get
    modalClasses() {
        return {
            'modal-xxl': this.isLarge,
            'modal-lg': !this.isLarge,
            'modal-dialog-scrollable': this.isScrollable
        };
    }

}
