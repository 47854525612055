
import StringInputField from "./StringInputField.vue";
import { Component, Model, Prop, Ref } from "vue-property-decorator";
import Vue from "vue";
import { IValidatedField } from "@/common/element/form/IValidatedField";

@Component({
    components: {
        StringInputField
    }
})
export default class NumberInputField extends Vue implements IValidatedField {
    @Model('input', {type: [String, Number]})
    readonly fieldValue!: number

    @Prop({required: false, type: Boolean, default: false})
    readonly isDisabled!: boolean;

    @Prop({required: false, type: Boolean, default: false})
    readonly isRequired!: boolean;

    @Prop({required: false, type: String, default: ''})
    readonly name!: string;

    @Prop({required: false, type: String, default: ''})
    readonly id!: string;

    @Prop({required: false, type: String, default: ''})
    readonly successMessage!: string;

    @Prop({required: false, type: String, default: ''})
    readonly errorMessage!: string;

    @Prop({required: false, type: String, default: ''})
    readonly label!: string;

    @Prop({required: false, type: String, default: ''})
    readonly placeholder!: string;

    @Prop({required: false, type: [Number, String]})
    readonly defaultValue!: number;

    @Ref()
    readonly inputField!: IValidatedField

    onChange(value: string) {
        const floatvalue = parseFloat(value);
        this.$emit('input', floatvalue < 0 ? Math.abs(floatvalue) : floatvalue);
    }

    protected toString(value: any): string {
        return (value || '').toString();
    }

    public validate() {
        return this.inputField.validate();
    }

    public reset(): void {
        this.inputField.reset();
    }
}
