import * as _ from 'lodash';

export enum ApiRequestStatus {
    SUCCESS = 'SUCCESS',
    INCORRECT_FORMAT = 'INCORRECT_FORMAT',
    FAILED = 'FAILED',
    FORBIDDEN = 'FORBIDDEN',
    NOT_FOUND = 'NOT_FOUND',
    BUSY = 'BUSY',
    NOT_LOGGED_IN = 'NOT_LOGGED_IN'
}

export class ApiResponseModel2<Type> {
    public data: Type;
    public message: string = '';
    public status: number;

    get
    isSuccess() {
        return this.status >= 200 && this.status < 300;
    }

    get
    isForbidden() {
        return this.status == 403;
    }

    get
    isError() {
        return this.status == 500;
    }

    constructor(request: any) {
        this.data = _.get(request, 'data');
        this.message = _.get(request, 'message', 'Server error');
        this.status = _.get(request, 'status', 500);
        if (this.status === 422) {
            // Model validation error
            const errors = (_.get(this.data, 'errors') ?? []) as string[];
            _.forEach(errors, propertyErrors => {
                this.message = propertyErrors[0];
            });
        }
    }
}

export class ApiResponseModel {
    public data: any | null;
    public message: string = '';
    public status: number;

    get
    isSuccess() {
        return this.status >= 200 && this.status < 300;
    }

    get
    isForbidden() {
        return this.status == 403;
    }

    constructor(request: any) {
        this.data = _.get(request, 'data', {});
        this.message = _.get(request, 'message', '');
        this.status = _.get(request, 'status', ApiRequestStatus.FAILED);
    }
}

export class ListResponseModel<Type> {
    public totalItems: number = 0;
    public totalPages: number = 0;
    public pageSize: number = 0;
    public items: Type[] = [];

    get
    isMoreTest(): boolean {
        return this.pageSize === this.items.length;
    }
}

