import Vue from 'vue';
import * as _ from 'lodash'
import Utils from "./service/Utils";

Vue.filter('phrase', function (value: string, params: object = {}) {
    if (!value) return '';
    return Utils.phrase(value, params);
});

Vue.filter('truncate', function (value: string, maxLetters: number = 255) {
    if (!value) return '';
    return value.length > maxLetters ? `${value.substring(0, maxLetters)}...` : value;
});

Vue.filter('full_date', function (value: number) {
    if (!value) return '';
    const date = new Date(value * 1000);
    return `${date.getUTCHours()}:${date.getUTCMinutes()} ${date.getUTCDay()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
});

Vue.filter('short_date', function (value: number) {
    if (!value) return '';
    const date = new Date(value * 1000);
    return `${date.getUTCDay()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
});

Vue.filter('schema_org_date', function (value: number) {
    if (!value) return '';
    const date = new Date(value * 1000);
    return `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${date.getUTCDay()}`;
});

Vue.filter('money_format', function (value: number) {
    return Utils.toMoneyFormat(value);
});

Vue.filter('number', function (value: string) {
    return value ? parseInt(value) : '0';
});

Vue.filter('thumbnail_from', function (object: object) {
    const images = _.get(object, 'images', []);
    if (images.length > 0) {
        return _.get(images[0], 'thumbnailUrl');
    }
    return '/images/product_default.png';
});

Vue.filter('image_from', function (object: object) {
    const images = _.get(object, 'images', []);
    if (images.length > 0) {
        return _.get(images[0], 'url');
    }
    return '/images/product_default.png';
});
