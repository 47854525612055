
import { required, minLength, maxLength, helpers } from '@vuelidate/validators'
import { Component, Model, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import _ from "lodash";
import { IValidatedField } from "@/common/element/form/IValidatedField";
import { ValidationRule, useVuelidate, Validation, ValidationRuleWithoutParams, BaseValidation } from "@vuelidate/core";
import {Validations} from 'vuelidate-property-decorators';

interface InputValidation extends IValidatedField, BaseValidation {
    minLength?: number;
    maxLength?: number;
    $invalid: boolean;
}

interface FieldValidationRules {
    required?: ValidationRuleWithoutParams;
    minLength?: ValidationRule;
    maxLength?: ValidationRule;
    regex?: ValidationRule;
}

@Component({
    components: {},
    setup: () => {
        return { $v: useVuelidate() };
    }
})
export default class StringInputField extends Vue {
    @Model('input', {type: String, required: false})
    readonly fieldValue!: string

    @Prop({required: false, type: String})
    readonly name!: string;

    @Prop({required: false, type: String})
    readonly id!: string;

    @Prop({required: false, type: String})
    readonly defaultValue!: string;

    @Prop({required: false, type: Boolean, default: true})
    readonly isRequired!: boolean;

    @Prop({required: false, type: Boolean, default: false})
    readonly isDisabled!: boolean;

    @Prop({required: false, type: Boolean, default: false})
    readonly isMultiple!: boolean;

    @Prop({required: false, type: Number, default: 0})
    readonly minLength!: number;

    @Prop({required: false, type: Number, default: 255})
    readonly maxLength!: number;

    @Prop({required: false, type: String, default: ''})
    readonly successMessage!: string;

    @Prop({required: false, type: String, default: ''})
    readonly label!: string;

    @Prop({required: false, type: String, default: ''})
    readonly placeholder!: string;

    @Prop({required: false, type: String, default: null})
    readonly valueMask!: string | null;

    @Prop({required: false, type: String, default: 'text'})
    readonly type!: string;

    @Prop({required: false, type: String, default: ''})
    readonly step!: string;

    @Prop({required: false, type: RegExp})
    readonly validationRegex!: RegExp;

    @Prop({required: false, type: Boolean, default: false})
    readonly isDense!: boolean;

    private innerFieldValue: string = '';
    private errorMessage: string = '';

    created() {
        if (this.defaultValue) {
            this.innerFieldValue = this.defaultValue
        } else if (this.fieldValue) {
            this.innerFieldValue = this.fieldValue
        }
    }

    @Validations()
    validations() {
        let validatorRules: FieldValidationRules = {};
        if (this.isRequired) {
            validatorRules.required = required;
        }
        if (this.minLength) {
            validatorRules.minLength = minLength(this.minLength);
        }
        if (this.maxLength) {
            validatorRules.maxLength = maxLength(this.maxLength);
        }
        if (this.validationRegex) {
            validatorRules.regex = helpers.regex(this.validationRegex);
        }
        return {
            innerFieldValue: validatorRules
        };
    }

    updateValue(value: string) {
        this.innerFieldValue = value;
        this.innerValidationField.$touch();
        this.$emit('input', value);
    }

    touch() {
        this.innerValidationField.$touch();
        this.$emit('onValidate', this.name, !this.innerValidationField.$invalid);
    }

    public validate() {
        this.innerValidationField.$touch();
        return !this.$v.$invalid;
    }

    reset() {
        this.innerFieldValue = '';
        this.$v.$reset();
    }

    get isShowError(): boolean | null {
        if (this.innerValidationField.$dirty) {
            return this.isError;
        }
        return null;
    }

    get innerValidationField(): InputValidation {
        return _.get(this.$v, 'innerFieldValue') as Validation | any;
    }

    get isShowMinLengthError() {
        return this.innerValidationField.minLength !== undefined && !this.innerValidationField.minLength;
    }

    get isShowMaxLengthError() {
        return this.innerValidationField.maxLength !== undefined && !this.innerValidationField.maxLength;
    }

    get isError() {
        return this.innerValidationField.$error || !!(this.errorMessage);
    }

    @Watch('fieldValue')
    fieldValueWatcher(value: string) {
        this.innerFieldValue = value;
    }
}
